<template>
	<div class="activity-search">
		<w-navTab titleText="活动搜索"></w-navTab>
		<div class="activity-search-header">
			<div class="search-bar">
				<div class="search-left">
					<div class="search-icon-search">
						<van-icon name="search" />
					</div>
					<div class="search-input"><input type="text" v-model="keywords" placeholder="请输入关键字" /></div>
					<div class="search-icon-clear">
						<van-icon name="clear" v-if="keywords != ''" @click="onClear" />
					</div>
				</div>
				<div class="search-right">
					<button v-if="keywords != ''" @click="onSearch">搜索</button>
					<button v-if="keywords == ''" @click="$router.back()">取消</button>
				</div>
			</div>
			<div class="filter">
				<van-dropdown-menu :active-color="themeColor()">
					<van-dropdown-item v-model="paramsSignInStatus" :options="signInStatusColumn" @change="onSearch" />
					<van-dropdown-item :title="orgName" ref="selectOrg" v-if="isCity()">
						<div class="filter-dept">
							<div :class="['filter-dept-item', { 'filter-dept-item-active': paramsOrgId == vo.id }]"
								v-for="vo in orgList" @click="onSelectOrg(vo)">
								{{ vo.name }}
							</div>
						</div>
					</van-dropdown-item>
					<van-dropdown-item v-model="paramsActivityType" :options="activityTypeColumn" @change="onSearch" />
				</van-dropdown-menu>
			</div>
		</div>
		<div class="active-search-body">
			<w-list class="activity-list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
				@onLoad="onLoad">
				<div class="activity-list">
					<activity-item v-for="vo in activityList" :activity="vo" @click="onClickItem"></activity-item>
				</div>
			</w-list>
		</div>
	</div>
</template>

<script>
import ActivityApi from '@/api/activity';

import WList from '@/components/List';
import ActivityItem from '@/components/ActivityItem';
import District from '@/vendor/district';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';
import ActionVendor from "@/vendor/action"

const keep = keepMiXin(['ActivityDetail']);
const scroll = scrollMiXin(['.activity-list-view']);

export default {
	name: 'ActivitySearch',
	inject: ['isCity', 'isDistrict', 'themeColor'],
	mixins: [scroll, keep],
	data() {
		return {
			loading: true,
			finished: false,
			isEmpty: false,
			keywords: '',
			signInStatusColumn: [{ text: '报名状态', value: 1 }, { text: '我可报名', value: 2 }],
			activityTypeColumn: [{ text: '活动类型', value: 0 }],
			activityList: [],
			orgList: [],
			orgName: '组织单位',
			paramsPage: 1,
			paramsLimit: 10,
			paramsOrgId: 0,
			paramsActivityType: 0,
			paramsSignInStatus: 1,
			districtId: "",
			userInfo: ""
		};
	},
	created() {

		this.districtId = District.getDistrictId()

		/**
		 * 非县级兼容
		 */
		if (this.isCity()) {
			this.getOrgList();
		} else {
			this.paramsOrgId = this.districtId;
		}

		this.onLoad();
		this.getActivityType();
	},
	methods: {
		onLoad() {
			this.loading = true;

			ActivityApi.getList({
				page: this.paramsPage,
				limit: this.paramsLimit,
				search: this.keywords,
				district_id: this.paramsOrgId,
				type: this.paramsSignInStatus,
				group_id: this.paramsActivityType
			}).then(result => {
				this.paramsPage++
				this.activityList = this.activityList.concat(result.data.list);

				if (this.activityList.length >= result.data.total) {
					this.finished = true;
				}

				if (result.data.total <= 0) {
					this.isEmpty = true;
				}

				this.loading = false;
			}).catch(error => {
				console.log(error);
			});
		},
		getActivityType() {
			ActivityApi.getGroup()
				.then(result => {
					let activityType = [];

					result.data.forEach((item, key) => {
						activityType.push({
							value: item.id,
							text: item.name
						});
					});

					this.activityTypeColumn = this.activityTypeColumn.concat(activityType);
				})
				.catch(error => {
					console.log(error);
				});
		},
		getOrgList() {
			ActivityApi.plate()
				.then(result => {
					this.orgList = result.data.list;
				})
				.catch(error => {
					console.log(error);
				});
		},
		onSelectOrg(data) {
			
			if(data.id == 2){
				return ActionVendor.jumpArea()
			}
			
			this.orgName = data.name;
			this.paramsOrgId = data.id;
			this.$refs.selectOrg.toggle();
			this.onSearch();
		},
		onClear() {
			this.keywords = '';
			this.onSearch();
		},
		onSearch() {
			this.paramsPage = 1;
			this.loading = true;
			this.finished = false;
			this.isEmpty = false;
			this.activityList = [];
			this.onLoad();
		},
		onClickItem(activity) {
			this.$router.push({
				name: 'ActivityDetail',
				params: {
					activityId: activity.id
				}
			});
		}
	},
	components: {
		WList,
		ActivityItem
	}
};
</script>

<style lang="less" scoped>
.activity-search {
	.activity-search-header {
		background-color: #fff;
		padding: 0 10px;
		// box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		position: relative;
		z-index: 10;

		.search-bar {
			width: 100%;
			display: flex;
			align-items: center;
			background-color: #fff;
			padding-top: 15px;

			.search-left {
				flex: 1;
				display: flex;
				align-items: center;
				background: #f2f2f2;
				border-radius: 100px;

				.search-icon-search {
					min-width: 30px;
					width: 30px;
					height: 30px;
					font-size: 26px;
					color: #aaa;
					padding: 0 5px;
					text-align: center;
					line-height: 30px;
				}

				.search-icon-clear {
					min-width: 30px;
					width: 30px;
					height: 30px;
					font-size: 18px;
					color: #aaa;
					padding: 0 5px;
					text-align: center;
					line-height: 30px;
				}

				.search-input {
					flex: 1;
					padding: 6px 0 5px;

					input {
						width: 100%;
						border: none;
						margin: 0;
						padding: 0;
						display: block;
						padding-right: 5px;
						height: 24px;
						line-height: 24px;
						background-color: transparent;
						font-size: 16px;
						color: #666;
					}
				}
			}

			.search-right {
				padding-left: 10px;
				min-width: 32px;

				button {
					display: block;
					background-color: transparent;
					padding: 0;
					margin: 0;
					border: none;
					height: 35px;
					line-height: 35px;
					color: #3377ff;
					font-size: 16px;
				}
			}
		}

		.filter {
			/deep/.van-dropdown-menu {
				.van-dropdown-menu__bar {
					box-shadow: none;
					height: 48px;

					.van-dropdown-menu__title--active .van-ellipsis {
						font-weight: bold;
					}
				}
			}

			.filter-dept {
				display: flex;
				flex-wrap: wrap;
				padding: 0 5px;

				.filter-dept-item {
					width: 110px;
					margin: 0 5px 10px;
					height: 36px;
					line-height: 36px;
					text-align: center;
					font-size: 14px;
					color: #333;
					background: #f2f2f2;
					border-radius: 6px;

					&.filter-dept-item-active {
						color: #3377ff;
						font-weight: bold;
						background: rgba(87, 130, 245, 0.3);
					}
				}
			}
		}
	}

	.active-search-body {
		box-sizing: border-box;
		height: calc(100vh - 98px);

		/deep/.list-footer {
			margin-bottom: 10px;
		}

		/deep/.activity-list {
			padding: 10px;

			.activity-item {
				margin-top: 10px;
				border-radius: 8px;

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}
</style>
