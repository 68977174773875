import Config from "@/vendor/config"
import Request from "@/api/request.js"

const getList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/list', params)
}

const getDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/detail', params)
}

const SignIn = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/signIn', params)
}

const plate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/index', params)
}

const praise = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/page/praise', params)
}

const getGroup = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/activity/groups')
}

export default {
	SignIn,
	getList,
	getDetail,
	plate,
	praise,
	getGroup
}
